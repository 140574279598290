import React, { useState } from "react"
import paymentStyles from "../../styles/checkout/payment.module.scss"
import SumUpWidget from "./sumUpWidget"


const Payment = ({promoCodes, isPromo, setOrder, isVisible, data, cssClass, clearCommittedCart, clearCommittedValues}) => {

    const [error, setError] = useState('')
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const emailRegExp = /^\S+@\S+\.\S+$/;

    const isEmpty = (data) => {
      return (data.contatti_cliente.nome === "" ||  data.contatti_cliente.cognome === "" || data.contatti_cliente.email === "" || 
      data.contatti_cliente.telefono === ""
       || data.indirizzo_fatturazione.indirizzo === "" || data.indirizzo_fatturazione.comune === "" 
      || data.indirizzo_fatturazione.cap === "" || data.indirizzo_fatturazione.provincia === "" )
    }

    const saveOrder = async (data) => {
      if (!isEmpty(data) && (data.contatti_cliente.email && emailRegExp.test(data.contatti_cliente.email))) {
          const response = await fetch(`${process.env.GATSBY_STRAPI_PATH}/ordinis`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          })
          if (!response.ok) {
            return "fail"
          }
          const order = await response.json()
          return order
        } else {
        setError('Siamo spiacenti ma a causa di un errore del server il tuo ordine non è stato registrato nei nostri sistemi... verifica i dati che hai inserito, ricarica la pagina e riprova, se il problema persiste contattaci direttamente su whatsapp o via email')
        return error
      } 
    }

    const sendEmailMailer = async (order) => {
      const email_response = await fetch(`${process.env.GATSBY_NETLIFY_MAILER_URL}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(order)
      })
      if (email_response.ok) {return "success"}
    }

    const handleSubmitOrderBonifico = async (e) => {
          e.preventDefault()
          setDisabled(true)
          handleSubmitOrder("bonifico bancario anticipato")
    }
    
    const handleSubmitOrder = async (method) => {
        setLoading(true)
        data.promo = isPromo ? promoCodes.join(' | ').toUpperCase() : ''
        data.metodo_pagamento = method
        delete data.breakdown;
        delete data.discount;
        const data_ricevuta = {...data};
        
        data.murrine = data.murrine.map(({ height, width, hash, ...item }) => item).map(({ color, nome, colore, finitura, promoAppend, ...item }) => 
          (finitura ? 
          {...item, nome: `${nome} | ${colore.trim().replace(/_/g, ' ')} | ${finitura.trim().replace(/_/g, ' ')}`.toUpperCase()} :
          promoAppend ?
           {...item, nome: `${nome} | COLORE COME FOTO | ${promoAppend}`.toUpperCase()} :    
           {...item, nome: `${nome} | ${color.trim().replace(/_/g, ' ')}`.toUpperCase()}))

        const order = await saveOrder(data);

        if (order === 'fail') {
          setLoading(false)
          setError(`Grazie per il tuo ordine, abbiamo ricevuto il tuo pagamento, ma a causa di un errore del server non ti abbiamo potuto inviare la nostra email di conferma. In sostituzione stampa pure questa pagina come ricevuta e contattaci per l'invio`);
          return error
        } else {
        data.murrine = data_ricevuta.murrine;
        delete order._id;
        delete order.__v;
        delete order.createdAt;
        delete order.published_at;
        delete order.updatedAt;

       const result = await sendEmailMailer(order)

        if (result === "success") {
          data_ricevuta.id = order.id
          setOrder(data_ricevuta)
          clearCommittedCart()
          clearCommittedValues()  
          updateMurrineQuantities(data_ricevuta.murrine)        
          return data_ricevuta;
        } else { 
          setLoading(false)
          setError(`Grazie per il tuo ordine numero ${order.id}, lo abbiamo registrato correttamente ma a causa di un errore del server non ti abbiamo potuto inviare la nostra email di conferma. Contattaci per l'invio.`)
          return error;
          }   
        }      
      }

      const updateMurrineQuantities = async (murrine) => {
        const murrineOnly = murrine.filter(item =>(!item.finitura && {id: item.id, quantity: item.quantity, color: item.color}))
        for (let murrina of murrineOnly) {
            const res = await fetch(`${process.env.GATSBY_STRAPI_PATH}/murrines/${murrina.id}`, {keepalive: true, method: "GET",})
            if (!res.ok) {
              return "fail"
            }
            const murrina_db = await res.json()
            const varianti = murrina_db.variante_colore
            let quantita = varianti.find(obj => obj.colore === murrina.color).quantita;
            let objIndex = varianti.findIndex((obj => obj.colore === murrina.color));
            let newQuantity = (quantita - murrina.quantity < 0) ? 0 : quantita - murrina.quantity

            murrina_db.variante_colore[objIndex].quantita = newQuantity

            const response = await fetch(`${process.env.GATSBY_STRAPI_PATH}/murrines/${murrina.id}`, {
              method: "PUT",
              keepalive: true,
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(murrina_db)
            })
            if (!response.ok) {
              return "fail"
            } 
          }
          return "success"
        }

    return (
    <>
      {isVisible && !loading && <><div className={`${paymentStyles.card} ${cssClass}`}>
          <button className={paymentStyles.btn} disabled={disabled} onClick={(e) => handleSubmitOrderBonifico(e)}>pago con bonifico anticipato</button>
            <p className={paymentStyles.alertBox}><span>ⓘ </span>I pagamenti con bonifico bancario anticipato vanno fatti a favore di V. & T. di Vinzio Laura su IBAN IT42W0306945450615300494384 con causale "La Murrina Borgomanero Shop"</p>
            {error && <p className={paymentStyles.alertRicevuta}>{error}</p>}
            {!error && <SumUpWidget 
                  handleSubmitOrder={handleSubmitOrder}    
                  data_={data}     
            />}
          </div></>}
      {loading && <div className={paymentStyles.cardWait}>Un attimo di pazienza ...</div>}        
        </>
    )
  }

export default Payment
const zona1NO = ["Boca,28010",
"Bogogno,28010",
"Briga Novarese,28010",
"Briga,28010", // added
"Cavallirio,28010",
"Cressa,28012",
"Cureggio,28060",
"Fontaneto d'Agogna,28010",
"Gargallo,28010",
"Gattico-Veruno,28013",
"Gattico,28013", // added
"Veruno,28013", // added
"Ghemme,28074",
"Gozzano,28024",
"Grignasco,28075",
"Invorio,28045",
"Maggiora,28014",
"Prato Sesia,28077",
"Prato,28077", // added
"Romagnano Sesia,28078",
"Romagnano,28078", // added
"Soriso,28010",
"Suno,28019"]

const zona2NO = ["Agrate Conturbia,28010",
"Agrate,28010", // added
"Arona,28041",
"Bolzano Novarese,28010",
"Bolzano,28010", // added
"Castelletto sopra Ticino,28053",
"Castelletto Ticino,28053",//added
"Castelletto,28053", //added
"Cavaglietto,28010",
"Cavaglio d'Agogna,28010",
"Cavaglio,28010",// added
"Comignago,28060",
"Dormelletto,28040",
"Fara Novarese,28073",
"Fara,28073",//added
"Momo,28015",
"Oleggio Castello,28040",
"Orta San Giulio,28016",
"Orta,28016",// added
"Orta S. Giulio,28016",// added
"Paruzzaro,28040",
"Pella,28010",
"Pettenasco,28028",
"Pisano,28010",
"Pogno,28076",
"San Maurizio d'Opaglio,28017",
"S. Maurizio d'Opaglio,28017", // added
"S. Maurizio Opaglio,28017", // added
"San Maurizio Opaglio,28017", // added
"Vaprio d'Agogna,28010",
"Vaprio,28010"]//added

const zona3NO = ["Ameno,28010",
"Armeno,28011",
"Barengo,28010",
"Bellinzago Novarese,28043",
"Bellinzago,28043",//added
"Biandrate,28061",
"Borgo Ticino,28040",
"Borgo,28040",//added
"Borgolavezzaro,28071",
"Briona,28072",
"Caltignaga,28010",
"Cameri,28062",
"Carpignano Sesia,28064",
"Carpignano,28064",//added
"Cerano,28065",
"Colazza,28010",
"Divignano,28010",
"Landiona,28064",
"Lesa,28040",
"Marano Ticino,28040",
"Massino Visconti,28040",
"Meina,28046",
"Mezzomerico,28040",
"Miasino,28010",
"Novara,28100",
"Oleggio,28047",
"Pombia,28050",
"Romentino,28068",
"San Nazzaro Sesia,28060",
"San Pietro Mosezzo,28060",
"Sillavengo,28064",
"Sizzano,28070",
"Sozzago,28060",
"Varallo Pombia,28040"]

const zona4NO = ["Casalbeltrame,28060",
"Casaleggio Novara,28060",
"Casaleggio,28060",//added
"Casalino,28060",
"Casalvolone,28060",
"Castellazzo Novarese,28060",
"Castellazzo,28060",//added
"Galliate,28066",
"Garbagna Novarese,28070",
"Garbagna,28070",//added
"Granozzo con Monticello,28060",
"Granozzo,28060",//added
"Mandello Vitta,28060",
"Mandello,28060",//added
"Nebbiuno,28010",
"Nibbiola,28070",
"Recetto,28060",
"Terdobbiate,28070",
"Tornaco,28070",
"Trecate,28069",
"Vespolate,28079",
"Vicolungo,28060",
"Vinzaglio,28060"]

const zona3VCO= ["Baveno,28831",
"Belgirate,28832",
"Ghiffa,28823",
"Gignese,28836",
"Madonna del Sasso,28894",
"Mergozzo,28802",
"Omegna,28887",
"Premia,28866",
"Stresa,28838",
"Verbania,28921",
"Verbania,28922",
"Verbania,28923",
"Verbania,28924",
"Verbania,28925"]

const zona4VCO=["Antrona Schieranco,28841",
"Antrona,28841",//added
"Anzola d'Ossola,28877",
"Anzola,28877",//added
"Arizzano,28811",
"Arola,28899",
"Aurano,28812",
"Baceno,28861",
"Bannio Anzino,28871",
"Bannio,28871",//added
"Bee,28813",
"Beura-Cardezza,28851",
"Cardezza,28851",//added
"Beura,28851",//added
"Bognanco,28842",
"Borgomezzavalle,28846",
"Brovello-Carpugnino,28833",
"Brovello,28833",//added
"Carpugnino,28833",//added
"Calasca-Castiglione,28873",
"Castiglione,28873",//added
"Calasca,28873",//added
"Cambiasca,28814",
"Cannero Riviera,28821",
"Cannobio,28822",
"Caprezzo,28815",
"Casale Corte Cerro,28881",
"Ceppo Morelli,28875",
"Cesara,28891",
"Cossogno,28801",
"Craveggia,28852",
"Crevoladossola,28865",
"Crodo,28862",
"Domodossola,28845",
"Druogno,28853",
"Formazza,28863",
"Germagno,28887",
"Gravellona Toce,28883",
"Gravellona,28883",//added
"Gurro,28828",
"Intragna,28816",
"Loreglia,28893",
"Macugnaga,28876",
"Malesco,28854",
"Masera,28855",
"Massiola,28895",
"Miazzina,28817",
"Montecrestese,28864",
"Montescheno,28843",
"Nonio,28891",
"Oggebbio,28824",
"Ornavasso,28877",
"Pallanzeno,28884",
"Piedimulera,28885",
"Pieve Vergonte,28886",
"Premeno,28818",
"Premosello-Chiovenda,28803",
"Premosello,28803",//added
"Chiovenda,28803",//added
"Quarna Sopra,28898",
"Quarna,28898",//added
"Quarna Sotto,28896",
"Quarna,28896",//added
"Re,28856",
"San Bernardino Verbano,28804",
"San Bernardino,28804",//added
"Santa Maria Maggiore,28857",
"Toceno,28858",
"Trarego Viggiona,28826",
"Trarego,28826",//added
"Trasquera,28868",
"Trontano,28859",
"Valle Cannobina,28827",
"Valstrona,28897",
"Vanzone con San Carlo,28879",
"Vanzone,28879",//added
"Vanzone San Carlo,28879",//added
"Varzo,28868",
"Vignone,28819",
"Villadossola,28844",
"Villette,28856",
"Vogogna,28805"]


const zona3B=[
    "Coggiola,13863",
    "Portula,13833",
    "Pray,13867",
    "Valdilana,13835"
    ]

const zona4B=[
        "Ailoche,13861",
        "Andorno Micca,13811",
        "Andorno,13811",//added
        "Benna,13871",
        "Biella,13900",
        "Bioglio,13841",
        "Borriana,13872",
        "Brusnengo,13862",
        "Callabiana,13821",
        "Camandona,13821",
        "Camburzano,13891",
        "Campiglia Cervo,13812",
        "Campiglia,13812",//added
        "Candelo,13878",
        "Caprile,13864",
        "Casapinta,13866",
        "Castelletto Cervo,13851",
        "Castelletto,13851",//added
        "Cavaglià,13881",
        "Cerrione,13882",
        "Cossato,13836",
        "Crevacuore,13864",
        "Curino,13865",
        "Donato,13893",
        "Dorzano,13881",
        "Gaglianico,13894",
        "Gifflenga,13874",
        "Graglia,13895",
        "Lessona,13853",
        "Magnano,13887",
        "Massazza,13873",
        "Masserano,13866",
        "Mezzana Mortigliengo,13831",
        "Mezzana,13831",//added
        "Miagliano,13816",
        "Mongrando,13888",
        "Mottalciata,13874",
        "Muzzano,13895",
        "Netro,13896",
        "Occhieppo Inferiore,13897",
        "Occhieppo,13897",//added
        "Occhieppo Superiore,13898",
        "Occhieppo,13898",//added
        "Pettinengo,13843",
        "Piatto,13844",
        "Piedicavallo,13812",
        "Pollone,13814",
        "Ponderano,13875",
        "Pralungo,13899",
        "Quaregna Cerreto,13854",
        "Quaregna,13854",//added
        "Ronco Biellese,13845",
        "Ronco,13845",//added
        "Roppolo,13883",
        "Rosazza,13815",
        "Sagliano Micca,13816",
        "Sala Biellese,13884",
        "Salussola,13885",
        "Sandigliano,13876",
        "Sordevolo,13817",
        "Sostegno,13868",
        "Strona,13823",
        "Tavigliano,13811",
        "Ternengo,13844",
        "Tollegno,13818",
        "Torrazzo,13884",
        "Valdengo,13855",
        "Vallanzengo,13847",
        "Valle San Nicolao,13847",
        "Veglio,13824",
        "Verrone,13871",
        "Vigliano Biellese,13856",
        "Vigliano,13856",//added
        "Villa del Bosco,13868",
        "Villanova Biellese,13877",
        "Villanova,13877",//added
        "Viverone,13886",
        "Zimone,13887",
        "Zubiena,13888",
        "Zumaglia,13848"]

        const zona3V=["Boccioleto,13022",
        "Guardabosone,13010",
        "Lozzolo,13045",
        "Postua,13010",
        "Quarona,13017",
        "Roasio,13060",
        "Varallo,13019",
        "Vocca,13020"
        ]
        
        const zona2V = [
        "Borgosesia,13011",
        "Cellio con Breia,13024",
        "Cellio,13024",//added
        "Gattinara,13045",
        "Ghislarengo,13030",
        "Lenta,13035",
        "Serravalle Sesia,13037",
        "Serravalle,13037",//added
        "Valduggia,13018"
        ]
        
        const zona4V=[
        "Alagna Valsesia,13021",
        "Alagna,13021",//added
        "Albano Vercellese,13030",
        "Albano,13030",//added
        "Alice Castello,13040",
        "Alto Sermenza,13029",
        "Arborio,13031",
        "Asigliano Vercellese,13032",
        "Asigliano,13032", //addded
        "Balmuccia,13020",
        "Balocco,13040",
        "Bianzè,13041",
        "Borgo d'Ale,13040",
        "Borgo Vercelli,13012",
        "Buronzo,13040",
        "Campertogno,13023",
        "Carcoforo,13026",
        "Caresana,13010",
        "Caresanablot,13030",
        "Carisio,13040",
        "Casanova Elvo,13030",
        "Cervatto,13025",
        "Cigliano,13043",
        "Civiasco,13010",
        "Collobiano,13030",
        "Costanzana,13033",
        "Cravagliana,13020",
        "Crescentino,13044",
        "Crova,13040",
        "Desana,13034",
        "Fobello,13025",
        "Fontanetto Po,13040",
        "Formigliana,13030",
        "Greggio,13030",
        "Lamporo,13046",
        "Lignana,13034",
        "Livorno Ferraris,13046",
        "Mollia,13020",
        "Moncrivello,13040",
        "Motta de' Conti,13010",
        "Olcenengo,13047",
        "Oldenico,13030",
        "Palazzolo Vercellese,13040",
        "Palazzolo,13040",//added
        "Pertengo,13030",
        "Pezzana,13010",
        "Pila,13020",
        "Piode,13020",
        "Prarolo,13012",
        "Quinto Vercellese,13030",
        "Quinto,13030",//added
        "Rassa,13020",
        "Rimella,13020",
        "Rive,13030",
        "Ronsecco,13036",
        "Rossa,13020",
        "Rovasenda,13040",
        "Salasco,13040",
        "Sali Vercellese,13040",
        "Sali,13040",//added
        "Saluggia,13040",
        "San Germano Vercellese,13047",
        "San Germano,13047",// added
        "San Giacomo Vercellese,13030",
        "San Giacomo,13030", // added
        "Santhià,13048",
        "Scopa,13027",
        "Scopello,13028",
        "Stroppiana,13010",
        "Tricerro,13038",
        "Trino,13039",
        "Tronzano Vercellese,13049",
        "Tronzano,13049", // added
        "Vercelli,13100",
        "Villarboit,13030",
        "Villata,13010"]

export const normalizeText = (s) => s.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase().split(' ').join('').split('-').join('').split(`'`).join('')


export const zona1 = [...zona1NO].map(item => normalizeText(item))  
export const zona2 = [...zona2NO, ...zona2V].map(item => normalizeText(item))    
export const zona3 = [...zona3NO, ...zona3B, ...zona3V, ...zona3VCO].map(item => normalizeText(item))   
export const zona4 = [...zona4NO, ...zona4B, ...zona4V, ...zona4VCO].map(item => normalizeText(item))    

export const province = [
    '',
    'Agrigento,AG',
    'Alessandria,AL',
    'Ancona,AN',
    'Aosta,AO',
    'Arezzo,AR',
    'Ascoli Piceno,AP',
    'Asti,AT',
    'Avellino,AV',
    'Bari,BA',
    'Barletta-Andria-Trani,BT',
    'Belluno,BL',
    'Benevento,BN',
    'Bergamo,BG',
    'Biella,BI',
    'Bologna,BO',
    'Bolzano,BZ',
    'Brescia,BS',
    'Brindisi,BR',
    'Cagliari,CA',
    'Caltanissetta,CL',
    'Campobasso,CB',
    'Carbonia-Iglesias,CI',
    'Caserta,CE',
    'Catania,CT',
    'Catanzaro,CZ',
    'Chieti,CH',
    'Como,CO',
    'Cosenza,CS',
    'Cremona,CR',
    'Crotone,KR',
    'Cuneo,CN',
    'Enna,EN',
    'Fermo,FM',
    'Ferrara,FE',
    'Firenze,FI',
    'Foggia,FG',
    'Forlì-Cesena,FC',
    'Frosinone,FR',
    'Genova,GE',
    'Gorizia,GO',
    'Grosseto,GR',
    'Imperia,IM',
    'Isernia,IS',
    'La Spezia,SP',
    "L'Aquila,AQ",
    'Latina,LT',
    'Lecce,LE',
    'Lecco,LC',
    'Livorno,LI',
    'Lodi,LO',
    'Lucca,LU',
    'Macerata,MC',
    'Mantova,MN',
    'Massa-Carrara,MS',
    'Matera,MT',
    'Medio Campidano,VS',
    'Messina,ME',
    'Milano,MI',
    'Modena,MO',
    'Monza e della Brianza,MB',
    'Napoli,NA',
    'Novara,NO',
    'Nuoro,NU',
    'Olbia-Tempio,OT',
    'Oristano,OR',
    'Padova,PD',
    'Palermo,PA',
    'Parma,PR',
    'Pavia,PV',
    'Perugia,PG',
    'Pesaro e Urbino,PU',
    'Pescara,PE',
    'Piacenza,PC',
    'Pisa,PI',
    'Pistoia,PT',
    'Pordenone,PN',
    'Potenza,PZ',
    'Prato,PO',
    'Ragusa,RG',
    'Ravenna,RA',
    'Reggio Calabria,RC',
    'Reggio Emilia,RE',
    'Rieti,RI',
    'Rimini,RN',
    'Roma,RM',
    'Rovigo,RO',
    'Salerno,SA',
    'Sassari,SS',
    'Savona,SV',
    'Siena,SI',
    'Siracusa,SR',
    'Sondrio,SO',
    'Taranto,TA',
    'Teramo,TE',
    'Terni,TR',
    'Torino,TO',
    'Ogliastra,OG',
    'Trapani,TP',
    'Trento,TN',
    'Treviso,TV',
    'Trieste,TS',
    'Udine,UD',
    'Varese,VA',
    'Venezia,VE',
    'Verbano-Cusio-Ossola,VB',
    'Vercelli,VC',
    'Verona,VR',
    'Vibo Valentia,VV',
    'Vicenza,VI',
    'Viterbo,VT'       
    ]

export const pickUpZone =['Novara,NO','Vercelli,VC','Varese,VA','Verbano-Cusio-Ossola,VB','Biella,BI']


import { useState, useEffect, useContext } from 'react';
import {StoreContext} from "../../context/storeContext"

const useForm = (validate) => {

  const [values, setValues] = useState({});
  const {committedValues, setCommittedValues, errors, setErrors} = useContext(StoreContext);
  const [errs, setErrs] = useState({});
  const [_, setIsSubmitting] = useState(false);
  const [error, setError] = useState('')
  
  useEffect(() => {
    if (typeof window !== `undefined`) {
      localStorage.setItem("user", JSON.stringify(committedValues));}
  }, [committedValues]);
 
  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({...errors, ...validate(committedValues, errors)});
    setIsSubmitting(true);
    if (committedValues.isSameForShipping === true) {
      setCommittedValues({...committedValues,
                nomeSpedizione : committedValues.nome,
                cognomeSpedizione : committedValues.cognome,
                indirizzoSpedizione : committedValues.indirizzo,
                comuneSpedizione : committedValues.comune,
                capSpedizione : committedValues.cap,
                provinciaSpedizione : committedValues.provincia,
              })}  
          }
  

  const handleChange = (event) => {
    event.persist();
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setValues(values => ({ ...values, [event.target.name]: value }));
    setErrs({...errs,  [event.target.name]: ''})
  }
  
  const handleChangeCommitted = (event) => {
    event.persist();
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setCommittedValues(values => ({ ...values, [event.target.name]: value }));
    setErrors({...errors,  [event.target.name]: ''})
  }

  return {
    handleChange,
    handleChangeCommitted,
    handleSubmit,
    values,
    errs,
    error,
  }
}

export default useForm
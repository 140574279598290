import React, {useEffect} from "react"
import orderSummaryStyles from "../../styles/checkout/order-summary.module.scss"
import {formatPrice} from "../../utilities/formatPrice"

const Ricevuta = ({data, cssClass}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])


    return (
        <div className={`${orderSummaryStyles.cardRicevuta} ${cssClass}`}>
            <h4 className={orderSummaryStyles.labelMainThanks}>grazie per il tuo ordine</h4>
            <h4 className={orderSummaryStyles.labelMain}>ricevuta n. {data.id}</h4>
            <h4 className={orderSummaryStyles.label}>i tuoi contatti</h4>
                <p className={orderSummaryStyles.data}>{data && data.contatti_cliente.nome} {data && data.contatti_cliente.cognome}</p>
                <p className={orderSummaryStyles.data}>{data && data.contatti_cliente.email}</p>
                <p className={orderSummaryStyles.data}>{data && data.contatti_cliente.telefono}</p>

                <h4 className={orderSummaryStyles.label}>indirizzo di fatturazione</h4>
                <p className={orderSummaryStyles.data}>{data && data.indirizzo_fatturazione.indirizzo}</p>
                <p className={orderSummaryStyles.data}>{data && data.indirizzo_fatturazione.comune} </p>
                <p className={orderSummaryStyles.data}>{data && data.indirizzo_fatturazione.cap} {data && data.indirizzo_fatturazione.provincia && data.indirizzo_fatturazione.provincia.slice(-2)}</p>
                
                <h4 className={orderSummaryStyles.label}>indirizzo di spedizione</h4>
                <p className={orderSummaryStyles.data}>{data && data.indirizzo_spedizione.nome} {data && data.indirizzo_spedizione.cognome}</p>
                <p className={orderSummaryStyles.data}>{data && data.indirizzo_spedizione.indirizzo}</p>
                <p className={orderSummaryStyles.data}>{data && data.indirizzo_spedizione.comune} </p>
                <p className={orderSummaryStyles.data}>{data && data.indirizzo_spedizione.cap} {data && data.indirizzo_spedizione.provincia && data.indirizzo_spedizione.provincia.slice(-2)}</p>
                
                <h4 className={orderSummaryStyles.label}>metodo di spedizione</h4>
                <p className={orderSummaryStyles.data}>{data && data.metodo_spedizione}</p>

                <h4 className={orderSummaryStyles.label}>tempi di consegna</h4>
                <p className={orderSummaryStyles.data}>I pezzi disponibili in negozio verranno consegnati entro 15 giorni lavorativi dal pagamento.
                I pezzi non disponibili in negozio verranno spediti entro 60 giorni dal pagamento</p>

                <h4 className={orderSummaryStyles.label}>metodo di pagamento</h4>
                <p className={orderSummaryStyles.data}>{data && data.metodo_pagamento}</p>
                <p className={orderSummaryStyles.dataInfo}>{data && (data.metodo_pagamento) && `ⓘ I pagamenti con bonifico bancario anticipato vanno fatti a favore di V. & T. di Vinzio Laura su IBAN IT42W0306945450615300494384 con causale "La Murrina Borgomanero Shop"`}</p>

                {data.quantita_totale_murrine !== 1 && <h4 className={orderSummaryStyles.label}>{data.quantita_totale_murrine} pezzi acquistati</h4>}
                {data.quantita_totale_murrine === 1 && <h4 className={orderSummaryStyles.label}>{data.quantita_totale_murrine} pezzo acquistato</h4>}

                {<ul className={orderSummaryStyles.list}>
                {data && data.murrine.map(item =>(item.finitura ? 
                  <li key={`${item.nome} | ${item.colore.trim().replace(/_/g, ' ')} | ${item.finitura.trim().replace(/_/g, ' ')}}`}>
                  <div  className={orderSummaryStyles.fotoBox}>

                  <img className={orderSummaryStyles.foto} alt={item.nome} src={`${process.env.GATSBY_CLOUDINARY_URL_THUMBNAIL}${item.hash}`} loading="lazy"/>
                   
                </div>
                <div className={orderSummaryStyles.name}>{item.nome} | {item.colore.trim().replace(/_/g, ' ')} | {item.finitura.trim().replace(/_/g, ' ')}</div> 
                  <div className={orderSummaryStyles.priceQtyWrapper}>
                    <div className={orderSummaryStyles.qty}>x {item.quantity}</div>
                    <div className={orderSummaryStyles.price}>{formatPrice(item.prezzo * item.quantity)}</div>
                  </div>
                  </li>
                  : <li key={`${item.nome} | ${item.color.trim().replace(/_/g, ' ')}`}>
                  <div  className={orderSummaryStyles.fotoBox}>
                  {(item.height >= item.width) ?
                    <img className={orderSummaryStyles.foto} alt={item.nome} src={`${process.env.GATSBY_CLOUDINARY_URL_THUMBNAIL}${item.hash}`} loading="lazy"/> : 
                    <img className={orderSummaryStyles.foto} alt={item.nome} src={`${process.env.GATSBY_CLOUDINARY_URL_THUMBNAIL_ROTATE}${item.hash}`} loading="lazy"/>}
                </div>
                <div className={orderSummaryStyles.name}>{item.promoAppend ?
                  `${item.nome} | COLORE COME FOTO | ${item.promoAppend}`.toUpperCase() :    
                  `${item.nome} | ${item.color.trim().replace(/_/g, ' ')}`.toUpperCase()}</div> 
                  <div className={orderSummaryStyles.priceQtyWrapper}>
                    <div className={orderSummaryStyles.qty}>x {item.quantity}</div>
                    <div className={orderSummaryStyles.price}>{formatPrice(item.prezzo * item.quantity)}</div>
                  </div>
                  </li>))}
                </ul>}
            {data.promo && <p className={orderSummaryStyles.dataLeftTotale}>{`Codice promo ${data.promo} applicato`}</p>}
            {<p className={orderSummaryStyles.dataLeftTotal}>{`totale merce ${data && formatPrice(data.totale_murrine)}`}</p>}
            {<p className={orderSummaryStyles.dataLeftTotal}>spedizione {data && formatPrice(data.costo_spedizione)}</p>}
            {<h3 className={orderSummaryStyles.dataLeftTotal}>{`Totale da pagare ${data && formatPrice(data.totale_da_pagare)}`}</h3>}
            {<p className={orderSummaryStyles.dataLeftRicevuta}>Tutti i prezzi includono l'IVA</p>}               
        </div>
    )

}

export default Ricevuta
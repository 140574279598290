export const getFotoHeight = (list, item) => (
    list.filter(ele => ((ele.nome === item.nome) && (ele.color === item.color)))[0].variante_colore.filter(el => el.colore === item.color)[0].foto[0].height

  )

export const getFotoWidth = (list, item) => (
    list.filter(ele => ((ele.nome === item.nome) && (ele.color === item.color)))[0].variante_colore.filter(el => el.colore === item.color)[0].foto[0].width
  )

export const getFotoThumbnailHash = (list, item) => (
    list.filter(ele => ((ele.nome === item.nome) && (ele.color === item.color)))[0].variante_colore.filter(el => el.colore === item.color)[0].foto[0].hash
  )
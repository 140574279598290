import {getFotoHeight, getFotoWidth,  getFotoThumbnailHash} from './murrinaUtils'
import {isDateEarlier, formatDate} from "./dateUtils"
import {zona1, zona2, zona3, zona4, normalizeText} from "./geoData"

export const getData = (promozioni, isPromo, promoCodes, committedCart, committedValues, cartCount, getCartTotal, removeItemFromCommittedCart, location='') => {   

  let shippingDestination = normalizeText(`${committedValues.comuneSpedizione},${committedValues.capSpedizione}`)
  
  const isZona1 = committedValues && committedValues.capSpedizione && zona1.includes(shippingDestination)
  const isZona2 = committedValues && committedValues.capSpedizione &&  zona2.includes(shippingDestination)
  const isZona3 = committedValues && committedValues.capSpedizione && zona3.includes(shippingDestination)
  const isZona4 = committedValues && committedValues.capSpedizione &&  zona4.includes(shippingDestination) 

  const scontObj = promozioni.filter(promoObj => promoCodes.includes(promoObj.codice.toUpperCase()))    
  const codici =  isPromo && scontObj ? scontObj.map(obj => obj['codice']) : ''

  const today = new Date().toLocaleDateString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit' })
  const dailyPromoObj = isPromo ? promozioni.filter(promo =>  (isDateEarlier(formatDate(promo.inizio), today) && isDateEarlier(today, formatDate(promo.fine)) && (promo.codice.trim()))) : []
  const activePromos = dailyPromoObj && dailyPromoObj.map(({fine, inizio, sconto, ...obj}) => {
  const murrineUnderPromoList = obj['murrine'].map(el => el.nome)
      return {...obj, 
      'murrineInCartUnderPromo': committedCart && committedCart.filter(murrina => murrineUnderPromoList && murrineUnderPromoList.includes(murrina.nome.trim()))
    }})
  const areThereActivePromos = activePromos.length !== 0 

  const murrine = committedCart.map(item => {
      const sconto = isPromo && scontObj && item.promo ? scontObj.filter(obj => obj.codice === item.promo.codice).map(obj => obj['sconto'])[0] : 0
      const murrineUnderPromoNameList = activePromos.filter(obj => codici.includes(obj.codice)) && item.promo ? activePromos.filter(obj => codici.includes(obj.codice) && obj.codice === item.promo.codice) : []
      const murrineInCartUnderPromoNameList = murrineUnderPromoNameList[0] && (murrineUnderPromoNameList[0].murrineInCartUnderPromo.map(obj => obj.nome))
      
      return (item.finitura ? 
      {id: item.strapiId, nome: item.nome, quantity: item.quantity, prezzo: item.prezzo, colore: item.colore.trim(), finitura: item.finitura.trim(), 
        foto: `${process.env.GATSBY_CLOUDINARY_URL_THUMBNAIL_EMAIL}${item.foto_luce[0].hash}`, height:item.foto_luce[0].height, width:item.foto_luce[0].width, 
        hash:item.foto_luce[0].hash}
      : (areThereActivePromos && murrineUnderPromoNameList[0] && (murrineInCartUnderPromoNameList.includes(item.nome)) ? 
      {id: item.strapiId, categoria: item.categoria, nome: item.nome, quantity: item.quantity, prezzo: (item.prezzo * (1 - sconto)), 
        color: item.color.trim(), promoAppend: promoCodes.includes(item.promo.codice) ? item.promo.codice : '' , foto: item.variante_colore[0].foto[0].url, 
      hash: getFotoThumbnailHash(committedCart, item), width: getFotoWidth(committedCart, item), height: getFotoHeight(committedCart, item), promo: item.promo} 
      : {id: item.strapiId, categoria: item.categoria, nome: item.nome, quantity: item.quantity, prezzo: item.prezzo, color: item.color.trim(), 
        foto: (getFotoHeight(committedCart, item) >= getFotoWidth(committedCart, item) ?  `${process.env.GATSBY_CLOUDINARY_URL_THUMBNAIL_EMAIL}${getFotoThumbnailHash(committedCart, item)}` :
      `${process.env.GATSBY_CLOUDINARY_URL_THUMBNAIL_ROTATE_EMAIL}${getFotoThumbnailHash(committedCart, item)}`), 
        hash: getFotoThumbnailHash(committedCart, item), width: getFotoWidth(committedCart, item), height: getFotoHeight(committedCart, item), promo: item.promo}))
  })

    
  const totale_murrine = isPromo ? parseFloat((getCartTotal(murrine)).toFixed(2)) : getCartTotal(murrine)

  const cartTotalFull = getCartTotal(committedCart)
  const cartTotalPromo = (dailyPromoObj === {}) ? cartTotalFull : getCartTotal(murrine)
  const quantita_totale_murrine = cartCount

  const areThereLucisInCart = murrine.some(murrina => murrina.finitura || murrina.categoria === 'lampade')
  const areThereObjectsInCart = murrine.some( murrina => murrina.categoria === 'oggettistica')

  let costo_spedizione = 0
  if (areThereLucisInCart || areThereObjectsInCart ){
      if (cartTotalPromo < 500 && cartTotalPromo !== 0) {
         costo_spedizione = 50
        }
      else if (cartTotalPromo < 1500 && cartTotalPromo !== 0){
        costo_spedizione = 100
      }
      else if (cartTotalPromo < 2500 && cartTotalPromo !== 0){
        costo_spedizione = 200
      }
      else if (cartTotalPromo < 4000 && cartTotalPromo !== 0){
        costo_spedizione = 300
      }
    } /* else {
      if (areThereObjectsInCart){
        costo_spedizione = ((cartTotalPromo < 100) && (cartTotalPromo !== 0) && (murrine.length !== 0)) ? 25 : (
          (cartTotalPromo < 200) && (cartTotalPromo !== 0) && (murrine.length !== 0) ? 15 : 0
        )
    }  */else {
      // orologi and gioielli
      costo_spedizione = ((cartTotalPromo < 50) && (cartTotalPromo !== 0) && (murrine.length !== 0)) ? 10 : 0
    }
  // }

  let costo_home_delivery
  if (committedValues.isHomeDelivery) {
    if (isZona1) {
      if (cartTotalPromo >= 30 && cartTotalPromo !== 0) {
        costo_home_delivery = 0
      } else {
        costo_home_delivery = 5
      }
    }
    else if (isZona2) {
        if (cartTotalPromo >= 100 && cartTotalPromo !== 0) {
          costo_home_delivery = 0
        } else {
          costo_home_delivery = 10
        }
    }
    else if (isZona3) {
        if (cartTotalPromo >= 800 && cartTotalPromo !== 0) {
          costo_home_delivery = 0
        } else {
          costo_home_delivery = 50
        }
    }
    else if (isZona4) {
        if (cartTotalPromo >= 1500 && cartTotalPromo !== 0) {
          costo_home_delivery = 0
        } else {
          costo_home_delivery = 100
        }
    }
  }

    costo_spedizione = (committedValues.isHomeDelivery) ? costo_home_delivery : costo_spedizione 
    // promo primavera start
    costo_spedizione = (areThereLucisInCart || areThereObjectsInCart) ? costo_spedizione : 0
    // promo primavera end
    costo_spedizione = (committedValues.isPickUp) ? 0 : costo_spedizione

    if (committedValues.capSpedizione === '28021') {
      costo_spedizione = 0
    }

    const metodo_spedizione = costo_spedizione === 0 ? (committedValues.isPickUp ? "ritiro in negozio a Borgomanero" : 
    ((committedValues.capSpedizione === '28021' || committedValues.isHomeDelivery) 
    ? "consegna gratuita coi nostri mezzi" : "consegna gratuita con corriere")) : 
    (committedValues.isHomeDelivery? "consegna coi nostri mezzi" : "consegna con corriere")

    const metodo_pagamento = "bonifico bancario anticipato"

    const totale_da_pagare = cartTotalPromo + costo_spedizione

    if (committedValues.provinciaSpedizione && committedValues.provincia && Object.keys(committedValues).length > 1 ) {
    const data = {
        murrine,
        contatti_cliente: {
          nome: committedValues.nome,
          cognome: committedValues.cognome,
          email: committedValues.email,
          telefono: committedValues.telefono,
        },
        indirizzo_spedizione: {
          nome: committedValues.nomeSpedizione,
          cognome: committedValues.cognomeSpedizione,
          indirizzo: committedValues.indirizzoSpedizione,
          comune: committedValues.comuneSpedizione,
          cap: committedValues.capSpedizione,
          provincia: committedValues.provinciaSpedizione.slice(-2),
        },
        indirizzo_fatturazione: {
          indirizzo: committedValues.indirizzo,
          comune: committedValues.comune,
          cap: committedValues.cap,
          provincia: committedValues.provincia.slice(-2),
        },
        metodo_pagamento,
        metodo_spedizione,
        totale_murrine,
        costo_spedizione,
        totale_da_pagare,
        quantita_totale_murrine,
        breakdown: cartTotalFull.toFixed(2),
        discount: (cartTotalFull - cartTotalPromo).toFixed(2)
      }
      return data
    }
      
  return {
      murrine: [],
      contatti_cliente: {
        nome: '',
        cognome: '',
        email: '',
        telefono: '',
      },
      indirizzo_spedizione: {
        nome: '',
        cognome: '',
        indirizzo: '',
        comune:  '',
        cap:  '',
        provincia:  '',
      },
      indirizzo_fatturazione: {
        indirizzo:  '',
        comune:  '',
        cap:  '',
        provincia:  '',
      },
      metodo_pagamento: '',
      metodo_spedizione: '',
      costo_spedizione: '',
      totale_murrine: '',
      totale_da_pagare: '',
      quantita_totale_murrine: '',
      breakdown: '',
      discount: ''
  }
}



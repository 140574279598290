import React, {useContext, useState, useRef} from "react"
import useForm from "../hooks/useForm";
import formStyles from "../../styles/checkout/form.module.scss"
import PhoneInput from 'react-phone-input-2'
import it from 'react-phone-input-2/lang/it.json'
import 'react-phone-input-2/lib/style.css'
import {validate} from "../hooks/validateForms"
import { StoreContext } from "../../context/storeContext"
import ArrowUpIcon from "../../assets/up-arrow.svg"
import ArrowDownIcon from "../../assets/down-arrow.svg"
import {zona1, zona2, zona3, zona4, normalizeText, province, pickUpZone} from "../../utilities/geoData"


  const Form = ({setIsBtnVisible, cssClass}) => {
    const {committedValues, setCommittedValues, errors, committedCart, getCartTotal} = useContext(StoreContext)
    const [status, setStatus] = useState({})
    const [isExpanded, setIsExpanded] = useState(true)
    const {
      handleChangeCommitted,
      handleSubmit,
    } = useForm(validate);

    let shippingDestination = normalizeText(`${committedValues.comuneSpedizione},${committedValues.capSpedizione}`)
    const isZona1 = committedValues && committedValues.capSpedizione && zona1.includes(shippingDestination)
    const isZona2 = committedValues && committedValues.capSpedizione &&  zona2.includes(shippingDestination)
    const isZona3 = committedValues && committedValues.capSpedizione && zona3.includes(shippingDestination)
    const isZona4 = committedValues && committedValues.capSpedizione &&  zona4.includes(shippingDestination) 

    const areThereLucisInCart = committedCart.some(murrina => murrina.finitura || murrina.categoria === 'lampade')
    const isHomeDeliveryCheaper = (committedValues && (committedValues['isSameForShipping'] ? 
    (committedValues['provincia'] && pickUpZone.includes(committedValues['provincia'])) :
     (committedValues['provinciaSpedizione'] && pickUpZone.includes(committedValues['provinciaSpedizione'])))) && 
    (isZona1 || isZona2 || areThereLucisInCart && isZona3 || (areThereLucisInCart && getCartTotal(committedCart) > 500 && isZona4))

  const handleSubmitUser = (e) => {
      setStatus({})
      handleSubmit(e)
      errors && Object.values(errors).every(x => (x === null || x === '' || x==='verifica i dati inseriti')) ? setIsBtnVisible(true) : setIsBtnVisible(false)
      errors && Object.values(errors).every(x => (x === null || x === '' || x==='verifica i dati inseriti')) && (topRef && topRef.current) &&
        topRef.current.scrollIntoView({ behavior: 'instant', block: 'center' })
      errors && Object.values(errors).every(x => (x === null || x === '' || x==='verifica i dati inseriti')) && setIsExpanded(!isExpanded)
  }

  const formInput = (input, errors, input_type='text') => (
        <div className={formStyles.formInput}>
            <label htmlFor={input}>{input.includes("S") ? input.substring(0, input.indexOf("S")).replace(/_/g, ' ') : input.replace(/_/g, ' ')}</label>
            <input autoComplete="off" type={input_type} name={input} onChange={handleChangeCommitted} value={committedValues[input] || ''} required />
            {errors[input] && <p className={formStyles.formError}>⚠ {errors[input]}</p>}         
        </div>
    )

    const formSelect = (input, list, errors) => (
      <div className={formStyles.formInput}>
        <label htmlFor={input}>{input.includes("S") ? input.substring(0, input.indexOf("S")) : input}</label>
        <select name={input} onChange={handleChangeCommitted} value={committedValues[input] || ''} required >
            {list.map((item,id) => (<option key={id} value={item}>{item}</option>))}
        </select>
        {errors[input] && errors[input] !== '' && <p className={formStyles.formError}>⚠ {errors[input]}</p>}       
    </div>
    )

  const formCheckbox = (input, label, isChecked, id) => {
    committedValues['isPickUp'] = committedValues && (committedValues['isSameForShipping'] ? 
      (committedValues['provincia'] && pickUpZone.includes(committedValues['provincia'])) && committedValues['isPickUp'] :
       (committedValues['provinciaSpedizione'] && pickUpZone.includes(committedValues['provinciaSpedizione'])) && committedValues['isPickUp'])
       
    committedValues['isHomeDelivery'] = committedValues && (committedValues['isSameForShipping'] ? 
      (committedValues['cap'] && (isZona1 || isZona2 || isZona3 || isZona4)) && committedValues['isHomeDelivery'] :
       (committedValues['capSpedizione'] &&  (isZona1 || isZona2 || isZona3 || isZona4)) && committedValues['isHomeDelivery'])

  return (
      <div className={`${formStyles.formInput} ${formStyles.reverse}`}>
        <label htmlFor={input}>{label}</label>
        <div className={formStyles.checkbox}>
          <input
            name={input}
            type="checkbox"
            onChange={handleChangeCommitted}
            defaultChecked={(input === 'isPickUp' || 'isHomeDelivery') ? 
            (isChecked || (committedValues[input]) ) : (isChecked && committedValues[input])}
            id={id}
          />  
          <label htmlFor={id}></label>
        </div>
    </div>)}

    const topRef = useRef()

    return (

              <form className={`${isExpanded ? `${formStyles.form} ${cssClass}` : `${cssClass} ${formStyles.form} ${formStyles.collapse}`}`} onSubmit={handleSubmitUser} noValidate>
              <h4 ref={topRef} className={formStyles.labelTop}>dati di contatto, fatturazione e spedizione</h4>
              {!isExpanded && <ArrowDownIcon className={formStyles.icon} onClick={() => setIsExpanded(!isExpanded)}/>}
              {isExpanded && <ArrowUpIcon className={formStyles.icon} onClick={() => setIsExpanded(!isExpanded)}/>}
              {isExpanded && <>
              <h4 className={formStyles.label}>i tuoi contatti</h4>
                {formInput('nome', errors)}
                {formInput('cognome', errors)}
                {formInput('email', errors, 'email')} 
                <div className={formStyles.formInputPhone}>
                 <label htmlFor='telefono'>telefono </label> 
                    <PhoneInput
                    country={'it'}
                    value={committedValues['telefono'] || null}
                    onChange={value => setCommittedValues({ ...committedValues, 'telefono': value})}
                    inputProps={{
                        name: 'telefono',
                        required: true,
                        placeholder: ''
                    }}
                    onlyCountries={['it']}
                    disableDropdown={true}
                    disableCountryCode={true}
                    buttonStyle={{backgroundColor: "#fff"}}
                    localization={it}
                    />
                    {errors['telefono'] && <p className={formStyles.formError}>⚠ {errors['telefono']}</p>}  
        
                </div>
                <h4 className={formStyles.label}>indirizzo di fatturazione</h4>
                {formInput('indirizzo', errors)}
                {formInput('comune', errors)}
                {formInput('cap', errors)}
                {formSelect('provincia', province, errors)} 
                <input type="hidden" value="1" name="embed" /> 
                <h4 className={formStyles.label}>indirizzo di spedizione</h4>
                {formCheckbox('isSameForShipping', `l'indirizzo di spedizione è lo stesso della fatturazione`, true, "indirizzo")} 
                {!committedValues['isSameForShipping'] && formInput('nomeSpedizione', errors)}
                {!committedValues['isSameForShipping'] && formInput('cognomeSpedizione', errors)}
                {!committedValues['isSameForShipping'] && formInput('indirizzoSpedizione', errors)}
                {!committedValues['isSameForShipping'] && formInput('comuneSpedizione', errors)}
                {!committedValues['isSameForShipping'] && formInput('capSpedizione', errors)}
                {!committedValues['isSameForShipping'] && formSelect('provinciaSpedizione', province, errors)} 
                {committedValues && (committedValues['isSameForShipping'] ? (committedValues['provincia'] && pickUpZone.includes(committedValues['provincia']))
                 : (committedValues['provinciaSpedizione'] && pickUpZone.includes(committedValues['provinciaSpedizione']))) 
                 && <><h4 className={formStyles.label}>opzione ritiro merce in negozio a Borgomanero</h4>
                {formCheckbox('isPickUp', `sì, voglio passare a ritirare 
                la merce nel vostro negozio, non speditela al mio domicilio`, false, "pickup")} </>}
                {committedValues && (committedValues['isSameForShipping'] ? (committedValues['cap'] && isHomeDeliveryCheaper)
                 : (committedValues['capSpedizione'] && isHomeDeliveryCheaper))
                 &&  <><h4 className={formStyles.label}>opzione consegna a domicilio coi nostri mezzi</h4>
                 {formCheckbox('isHomeDelivery', `sì, voglio la consegna a domicilio fatta direttamente da voi, non spedite con il corriere`, false, "homeDelivery")} </>}
                <button type="submit" className={formStyles.btn} >Conferma i tuoi dati</button>
                {errors['summary'] && <p className={formStyles.errorSummary}>⚠ {errors['summary']}</p>} </>}
              </form>
 
          
    );
  };
  
  export default Form
import React, {useState, useRef, useEffect} from 'react'
import useScript from 'react-script-hook';
import paymentStyles from "../../styles/checkout/payment.module.scss"


const SumUpWidget = ({handleSubmitOrder, data_}) => {
    const [loading, error] = useScript({ src: `https://gateway.sumup.com/gateway/ecom/card/v2/sdk.js`, checkForExisting: true});
    const [disabled, setDisabled] = useState(false)
    const [waiter, setWaiter] = useState(false)
    const [error1, setError1] = useState(null);

 /*global SumUpCard*/ //

 const onClickHandler = async (e) => {
  e.preventDefault()
  setDisabled(true)
  setWaiter(true)
  const response = await fetch(`${process.env.GATSBY_SUMUP_TOKEN_URL}`, {
    method: "POST",
    headers: {
    "Content-Type": "application/json"
    },
    body: JSON.stringify({cognome : data_.contatti_cliente.cognome, amount: data_.totale_da_pagare})
  })

  const checkoutId = await response.text()

    if (response.ok) {
        const sumupCard = !loading && SumUpCard.mount({
        checkoutId,
        id: "sumup-card",
        amount: `${data_.totale_da_pagare}`,
        currency: "EUR",
        locale: "it-IT",
        onLoad: setWaiter(false),
        onResponse: async function(type, body) {
          if (type === 'success') {
            const response = await fetch(`${process.env.GATSBY_SUMUP_TOKEN_URL}?checkout_id=${checkoutId}`)
            if (response.ok) {
              const data = await response.text()
              if (data === 'PAID') {
                  sumupCard.unmount()
                  setWaiter(true)
                  handleSubmitOrder("carta via sumup")
                }
              }
            } 
          }
      }) 
    } else {
        setWaiter(false)
        setError1('fail')
      }
  }

if (waiter) {
  return   <div className={paymentStyles.cardWait}>Un attimo di pazienza ...</div>
}

if (error1) {
  return  <p className={paymentStyles.alertRicevuta}>Siamo spiacenti, il tuo pagamento con Sumup non è stato completato... ricarica la pagina e riprova, se il problema persiste contattaci direttamente su whatsapp o via email</p>
}

return (
  <>
    <button className={paymentStyles.btn} disabled={disabled} onClick={onClickHandler}>pago con carta</button>
    <div className={paymentStyles.sumup} id="sumup-card"></div>
  </>
  ) 
}
        

export default SumUpWidget

import React, { useState, useRef,useEffect } from "react"
import orderSummaryStyles from "../../styles/checkout/order-summary.module.scss"
import formStyles from "../../styles/checkout/form.module.scss"
import {formatPrice} from "../../utilities/formatPrice"
import {isDateEarlier, formatDate} from "../../utilities/dateUtils"
import {Link} from 'gatsby'
import ArrowUpIcon from "../../assets/up-arrow.svg"
import ArrowDownIcon from "../../assets/down-arrow.svg"

const OrderSummary = ({promoCodes, setPromoCodes, promozioni, isBtnVisible, setIsVisible, setIsPromo, isPromo, cssClass, data}) => {

    const today = new Date().toLocaleDateString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit' })
    const dailyPromoObj = promozioni.filter(promo =>  (isDateEarlier(formatDate(promo.inizio), today) && isDateEarlier(today, formatDate(promo.fine)) && (promo.codice.trim())))
    const activePromos = dailyPromoObj && dailyPromoObj.map(({fine, inizio, sconto, ...obj}) => {
      const murrineUnderPromoList = obj['murrine'].map(el => el.nome)
      return {...obj, 
      'murrineInCartUnderPromo': data.murrine && data.murrine.filter(murrina => murrineUnderPromoList && murrineUnderPromoList.includes(murrina.nome.trim()))
    }})
    
    const areThereActivePromos = activePromos.length !== 0 
    const murrineInCartUnderPromoCount = activePromos.map(obj => obj.murrineInCartUnderPromo.length).reduce((a, b) => a + b, 0)

    const [promoCode, setPromoCode] = useState('')
    const [error, setError] = useState('')
    const [promoError, setPromoError] = useState('')
    const [value, setValue] = useState(false)
    const [isExpanded, setIsExpanded] = useState(true)
    const info = `per proseguire torna indietro e conferma i tuoi dati di contatto e di fatturazione`
    const emailRegExp = /^\S+@\S+\.\S+$/;
    const topORef = useRef()

    const handleChangeCheckbox = (event) => {
      event.persist();
      setValue(event.target.checked);
    }

    const handleChangePromo = (event) => {
      event.persist();
      setPromoCode(event.target.value);
      setPromoError('')
    }

    const handleSubmitPromo = (event) => {
      event.preventDefault();
      const activePromo = activePromos.filter(activePromoObj => activePromoObj.codice === promoCode.toUpperCase())

      const isThisPromoActive = activePromo.length === 1
      if (!areThereActivePromos || !isThisPromoActive) {
        setPromoError('Codice promo non valido')
        return
      } 
       if (activePromo && activePromo[0].murrineInCartUnderPromo.length === 0) {
        setPromoError('Promo non applicabile ai pezzi scelti') 
        return
      }
      setPromoError('') 
      setIsPromo(true)
      !promoCodes.includes(promoCode.trim().toUpperCase()) && setPromoCodes([...promoCodes, promoCode.trim().toUpperCase()])
      }

    
    const formInputPromo = (input="promo", promoError, inputType='text') => (
      <>
      <h4 className={orderSummaryStyles.labelPromo}>Hai un codice promo?</h4>
      <p>In caso di più promozioni, inserisci un codice alla volta</p>
      <form className={orderSummaryStyles.formPromo} onSubmit={handleSubmitPromo} noValidate>
        <div className={orderSummaryStyles.formInputPromo}>
          <label htmlFor={input}></label>
          <input autoComplete="off" type={inputType} name={input} onChange={handleChangePromo} value={promoCode || ''} required />
          {promoError && <p className={formStyles.formErrorPromo}>⚠ {promoError}</p>}   
        </div>
        <button type="submit" className={formStyles.btn}>Applica lo sconto</button>
      </form>
      </>
  )

    const formCheckboxPay = (input, isChecked, id) => 
    (
        <div className={`${formStyles.formInput} ${formStyles.reverse}  ${orderSummaryStyles.checkbox}`}>
          <label htmlFor={input}>acconsento ai termini delle <Link to="/condizioni-di-vendita">condizioni di vendita</Link> e acconsento al trattamento dei miei dati personali in accordo con la <Link to="/privacy-policy">privacy policy</Link></label>
          <div className={formStyles.checkbox}>
            <input
              name={input}
              type="checkbox"
              onChange={handleChangeCheckbox}
              defaultChecked={isChecked || value}
              id={id}
            />  
            <label htmlFor={id}></label>
          </div> 
      </div>  
    )

     const isEmpty = (data) => {
      return (data.contatti_cliente.nome === "" ||  data.contatti_cliente.cognome === "" || data.contatti_cliente.email === "" || 
      data.contatti_cliente.telefono === "" || data.indirizzo_spedizione.indirizzo === "" || data.indirizzo_spedizione.comune === "" 
      || data.indirizzo_spedizione.cap === "" || data.indirizzo_spedizione.provincia === "" || data.indirizzo_spedizione.nome === "" || 
      data.indirizzo_spedizione.cognome === "" || data.indirizzo_fatturazione.indirizzo === "" || data.indirizzo_fatturazione.comune === "" 
      || data.indirizzo_fatturazione.cap === "" || data.indirizzo_fatturazione.provincia === "" )
    }

      const handlePreOrderSubmit = (event) => {
        event.preventDefault()
        setPromoError('')
        setPromoCode('')
        if (isEmpty(data)) {
          setError('torna indietro, verifica la completezza dei tuoi dati e confermali')
          return error
        }  
        if (data && data.contatti_cliente.email && !emailRegExp.test(data.contatti_cliente.email)) {
          setError('La tua email non è valida, verificala e confermala di nuovo')
          return error
        }
        if (data && data.murrine === 0 ) {
          setError('il tuo carrello è vuoto, ritorna allo shop per riempirlo!')
          return error
        }  
        if (value) {         
          setError('')
          setIsVisible(true)
          setIsExpanded(!isExpanded)
        } else {setError('per proseguire occorre il tuo consenso alle condizioni di vendita e alla privacy')
          return error
      }
    }

     
   useEffect(() => {
    if (!isExpanded) {
      (topORef && topORef.current ) && topORef.current.scrollIntoView({ behavior: 'instant', block: 'center' })
    }
  }, [isExpanded]);
    
    return (
        <div  className={`${orderSummaryStyles.card} ${cssClass}`}>
              <h4 ref={topORef} className={orderSummaryStyles.labelTop}>riepilogo dell'ordine, promo e totale da pagare</h4>
              {!isExpanded && <ArrowDownIcon className={orderSummaryStyles.icon} onClick={() => setIsExpanded(!isExpanded)}/>}
              {isExpanded && <ArrowUpIcon className={orderSummaryStyles.icon} onClick={() => setIsExpanded(!isExpanded)}/>}
              {isExpanded && <>
            
                <h4 className={orderSummaryStyles.label}>i tuoi contatti</h4>
                <p className={orderSummaryStyles.data}>{data && data.contatti_cliente.nome} {data && data.contatti_cliente.cognome}</p>
                <p className={orderSummaryStyles.data}>{data && data.contatti_cliente.email}</p>
                <p className={orderSummaryStyles.data}>{data && data.contatti_cliente.telefono}</p>

                <h4 className={orderSummaryStyles.label}>indirizzo di fatturazione</h4>
                <p className={orderSummaryStyles.data}>{data && data.indirizzo_fatturazione.indirizzo}</p>
                <p className={orderSummaryStyles.data}>{data && data.indirizzo_fatturazione.comune} </p>
                <p className={orderSummaryStyles.data}>{data && data.indirizzo_fatturazione.cap} {data && data.indirizzo_fatturazione.provincia && data.indirizzo_fatturazione.provincia.slice(-2)}</p>
                
                <h4 className={orderSummaryStyles.label}>indirizzo di spedizione</h4>
                <p className={orderSummaryStyles.data}>{data && data.indirizzo_spedizione.nome} {data && data.indirizzo_spedizione.cognome}</p>
                <p className={orderSummaryStyles.data}>{data && data.indirizzo_spedizione.indirizzo}</p>
                <p className={orderSummaryStyles.data}>{data && data.indirizzo_spedizione.comune} </p>
                <p className={orderSummaryStyles.data}>{data && data.indirizzo_spedizione.cap} {data && data.indirizzo_spedizione.provincia && data.indirizzo_spedizione.provincia.slice(-2)}</p>
                
                <h4 className={orderSummaryStyles.label}>metodo di spedizione</h4>
                {data && data.contatti_cliente.length !== 0 && <p className={orderSummaryStyles.data}>{data && data.metodo_spedizione}</p>}

                <h4 className={orderSummaryStyles.label}>tempi di consegna</h4>
                {data && data.contatti_cliente.length !== 0 && <p className={orderSummaryStyles.data}>I pezzi disponibili in negozio verranno consegnati entro 15 giorni lavorativi dal pagamento.
        I pezzi non disponibili in negozio verranno spediti entro 60 giorni dal pagamento</p>}

                {data && data.quantita_totale_murrine !== 1 && data.quantita_totale_murrine !==0 && <h4 className={orderSummaryStyles.label}>Hai {data && data.quantita_totale_murrine !== 1 && data.quantita_totale_murrine} pezzi nel carrello</h4>}
                {data && data.quantita_totale_murrine === 1 && <h4 className={orderSummaryStyles.label}>Hai {data.quantita_totale_murrine} pezzo nel carrello</h4>}
                {data && data.murrine.length !== 0 && <ul className={orderSummaryStyles.list}>
                {data && data.murrine.map(item =>(item.finitura ? 
                  <li key={`${item.nome} | ${item.colore.trim().replace(/_/g, ' ')} | ${item.finitura.trim().replace(/_/g, ' ')}}`}>
                  <div  className={orderSummaryStyles.fotoBox}>

                  <img className={orderSummaryStyles.foto} alt={item.nome} src={`${process.env.GATSBY_CLOUDINARY_URL_THUMBNAIL}${item.hash}`} loading="lazy"/>
                   
                </div>
                <div className={orderSummaryStyles.name}>{item.nome} | {item.colore.trim().replace(/_/g, ' ')} | {item.finitura.trim().replace(/_/g, ' ')}</div> 
                  <div className={orderSummaryStyles.priceQtyWrapper}>
                    <div className={orderSummaryStyles.qty}>x {item.quantity}</div>
                    <div className={orderSummaryStyles.price}>{formatPrice(item.prezzo * item.quantity)}</div>
                  </div>
                  </li>
                  : <li key={`${item.nome} | ${item.color.trim().replace(/_/g, ' ')}`}>
                  <div  className={orderSummaryStyles.fotoBox}>
                  {(item.height >= item.width) ?
                    <img className={orderSummaryStyles.foto} alt={item.nome} src={`${process.env.GATSBY_CLOUDINARY_URL_THUMBNAIL}${item.hash}`} loading="lazy"/> : 
                    <img className={orderSummaryStyles.foto} alt={item.nome} src={`${process.env.GATSBY_CLOUDINARY_URL_THUMBNAIL_ROTATE}${item.hash}`} loading="lazy"/>}
                </div>
                <div className={orderSummaryStyles.name}>{item.promoAppend ? `${item.nome} | COLORE COME FOTO | ${item.promoAppend}` :  `${item.nome} | ${item.color.trim().replace(/_/g, ' ')}`}</div> 
                  <div className={orderSummaryStyles.priceQtyWrapper}>
                    <div className={orderSummaryStyles.qty}>x {item.quantity}</div>
                    <div className={orderSummaryStyles.price}>{formatPrice(item.prezzo * item.quantity)}</div>
                  </div>
                  </li>))}
                </ul>}
                {isPromo && promoCodes.length > 0 && data.quantita_totale_murrine && (murrineInCartUnderPromoCount > 0) && areThereActivePromos && <p className={orderSummaryStyles.dataLeftTotale}>{`Codice promo ${promoCodes.join(' | ').toUpperCase()} applicato`}</p>}
                {data && data.murrine.length !== 0 && <p className={orderSummaryStyles.dataLeftTotal}>{`totale merce ${data && formatPrice(data.totale_murrine)}`}</p>}
                {data && data.contatti_cliente.length !== 0 && (data.murrine.length !== 0 ) && <p className={orderSummaryStyles.dataLeftTotal}>spedizione {data && formatPrice(data.costo_spedizione)}</p>}
                {data && data.quantita_totale_murrine !== 0 && <h3 className={orderSummaryStyles.dataLeftTotale}>{`totale da pagare ${data && formatPrice(data.totale_da_pagare)}`}</h3>}
                {data && data.quantita_totale_murrine !== 0 && <p className={orderSummaryStyles.dataLeft}>tutti i prezzi includono l'IVA</p>}         
                {data && data.contatti_cliente.length !== 0 && areThereActivePromos && formInputPromo('promo', promoError)}
                {!isBtnVisible && <p className={`${orderSummaryStyles.data} ${orderSummaryStyles.alert}`}><span>ⓘ </span>{info}</p>}
                {isBtnVisible && <form  className={orderSummaryStyles.form} onSubmit={handlePreOrderSubmit} noValidate>
                {formCheckboxPay("isOKToPay", false, "OKToPay")}
                <button type="submit" className={orderSummaryStyles.btn}>Conferma il tuo ordine</button>
                {error && <p className={orderSummaryStyles.alert}>⚠ {error}</p>}
            </form>} </>}
      
        </div>
    )

}

export default OrderSummary
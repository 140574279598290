
const emailRegex = /^\S+@\S+\.\S+$/;
var cfRegex = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;


export const validate = (committedValues, errors) => {
    if (!committedValues.email) {
      errors.email = 'inserisci la tua email';
    } else if (!emailRegex.test(committedValues.email)) {
      errors.email = `email non valida`;
    }
    if (!committedValues.nome) {
      errors.nome = 'inserisci il tuo nome';
    }
    if (!committedValues.cognome) {
        errors.cognome = 'inserisci il tuo cognome';
    }
    if (!committedValues.indirizzo) {
      errors.indirizzo = 'inserisci il tuo indirizzo';
    }
    if (!committedValues.comune) {
      errors.comune = 'inserisci il tuo comune';
    }
    if (!committedValues.cap) {
      errors.cap = 'inserisci il tuo CAP';
    } else if (!/^\d+$/.test(committedValues.cap) || committedValues.cap.length !== 5) {
      errors.cap = 'CAP non valido';
    }
    if (!committedValues.provincia) {
      errors.provincia = 'inserisci la tua provincia';
    }
    if (!committedValues.telefono) {
      errors.telefono = 'inserisci il tuo numero di telefono';
    } else {  errors.telefono = ''; }


    if (committedValues.isSameForShipping === true) {
      errors.nomeSpedizione = ''
      errors.cognomeSpedizione = ''
      errors.indirizzoSpedizione = ''
      errors.comuneSpedizione = ''
      errors.capSpedizione = ''
      errors.provinciaSpedizione = ''
    }

    if (committedValues.isSameForShipping === false) {
      if (!committedValues.nomeSpedizione) {
        errors.nomeSpedizione = 'inserisci il tuo nome';
      }
      if (!committedValues.cognomeSpedizione) {
          errors.cognomeSpedizione = 'inserisci il tuo cognome';
      }
      if (!committedValues.indirizzoSpedizione) {
        errors.indirizzoSpedizione = 'inserisci il tuo indirizzo';
      }
      if (!committedValues.comuneSpedizione) {
        errors.comuneSpedizione = 'inserisci il tuo comune';
      }
      if (!committedValues.capSpedizione) {
        errors.capSpedizione = 'inserisci il tuo CAP';
      } else if (!/^\d+$/.test(committedValues.capSpedizione) || committedValues.capSpedizione.length !== 5) {
        errors.capSpedizione = 'questo CAP non è valido';
      }
      if (!committedValues.provinciaSpedizione) {
        errors.provinciaSpedizione = 'inserisci la tua provincia';
      }
    }

    if (committedValues.isPickUp && committedValues.isHomeDelivery) {errors.summary = 'seleziona una sola opzione di consegna: ritiro in negozio o consegna low cost'} 
    else if (!Object.values(errors).every(x => (x === null || x === '' || x==='verifica i dati inseriti' || x==='seleziona una sola opzione di consegna: ritiro in negozio o consegna low cost'))) {
      errors.summary =  'verifica i dati inseriti'
    }else {errors.summary = ''}

    return errors;
  };
